import React from 'react'
import '../App.css'
import Particles from 'react-particles-js'
import Logo from '../Assets/LogoLow.png'
import {Helmet} from 'react-helmet'

function Home() {

    return (
      <div className="page">
        <Helmet>
        <title>Dev Dominus Digital Solutions</title>
        <meta name="description" content="We Build Websites, Perform SEO setup and provide all forms of digital design" />
        </Helmet>
        <Particles className="background"
            params={{
              "particles": {
                "number": {
                  "value": 60,
                  "density": {
                    "enable": true,
                    "value_area": 650
                  }
                },
                "color": {
                  "value": "#ff8f00"
                },
                "shape": {
                  "type": "circle",
                  "stroke": {
                    "width": 0,
                    "color": "#000000"
                  },
                  "polygon": {
                    "nb_sides": 8
                  },
                },
                "opacity": {
                  "value": 1,
                  "random": false,
                  "anim": {
                    "enable": false,
                    "speed": 1,
                    "opacity_min": 0.1,
                    "sync": false
                  }
                },
                "size": {
                  "value": 3,
                  "random": true,
                  "anim": {
                    "enable": false,
                    "speed": 40,
                    "size_min": 0.1,
                    "sync": false
                  }
                },
                "line_linked": {
                  "enable": true,
                  "distance": 160,
                  "color": "#ff8f00",
                  "opacity": 0.69,
                  "width": 0.80,
                },
                "move": {
                  "enable": true,
                  "speed": 2,
                  "direction": "top-right",
                  "random": false,
                  "straight": false,
                  "out_mode": "out",
                  "bounce": false,
                  "attract": {
                    "enable": false,
                    "rotateX": 1200,
                    "rotateY": 1200
                  }
                }
              },
              "interactivity": {
                "detect_on": "canvas",
                "events": {
                  "onhover": {
                    "enable": false,
                    "mode": "bubble"
                  },
                  "onclick": {
                    "enable": true,
                    "mode": "repulse"
                  },
                  "resize": true
                },
                "modes": {
                  "grab": {
                    "distance": 400,
                    "line_linked": {
                      "opacity": 1
                    }
                  },
                  "bubble": {
                    "distance": 400,
                    "size": 40,
                    "duration": 2,
                    "opacity": 8,
                    "speed": 3
                  },
                  "repulse": {
                    "distance": 100,
                    "duration": 0.4
                  },
                  "push": {
                    "particles_nb": 4
                  },
                  "remove": {
                    "particles_nb": 2
                  }
                }
              },
              retina_detect:false
              
            }}/>
            <div className="home-parent">
            <div className="span-middle">
            <img src={Logo} className="logo" alt=""/>
            
            <h1 className="home-header">Dev Dominus</h1>
            <h3 className="home-subtext">Digital Solutions</h3>
            
            </div>
            </div>
        
        </div>
    )
}

export default Home